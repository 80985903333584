import { Component, OnInit} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { ResourceService } from '../service/resource.service';
import { UserService } from '../service/user.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {Location} from '@angular/common';
declare const recurly: any;
import * as $ from 'jquery';
import * as AppConfigs from '../app.constants';

@Component({
  selector: 'upgrade',
  templateUrl: './upgrade.component.html'
})
export class UpgradeComponent implements OnInit {
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private titleService: Title, private resourceService: ResourceService, private userService: UserService, private _location: Location) { };
  public docTitle: string = '';
  public productType: string = '';
  public paymentInfo: any = null;
  public hideRecurlyForm: boolean = true;
  public selectPaymentOpt: string = 'existing';
  public userData: any;
  public planType: string = '';
  public countryList: any = [];
  public stateList: any = [];
  public countryCode: string = '';
  public showRecurlySubmitBtn: boolean = false;
  public notificationMsg: string = '';
  public paymentErrMsg: string = '';
  public isPaymentErr: boolean = false;
  public isRecurlyErr: boolean = false;
  public recurlyErrMsg: string = '';
  public showConfirmAddr: boolean = false;
  public verifiedAddrRes: any = null;
  public confirmAddrRadio: any = null;
  public usStateMap = {AL: 'Alabama', AK: 'Alaska', AZ: 'Arizona', AR: 'Arkansas', CA: 'California', CO: 'Colorado', CT: 'Connecticut', DE: 'Delaware', DC: 'District of Columbia', FL: 'Florida', GA: 'Georgia', HI: 'Hawaii', ID: 'Idaho', IL: 'Illinois', IN: 'Indiana', IA: 'Iowa', KS: 'Kansas', KY: 'Kentucky', LA: 'Louisiana', ME: 'Maine', MD: 'Maryland', MA: 'Massachusetts', MI: 'Michigan', MN: 'Minnesota', MS: 'Mississippi', MO: 'Missouri', MT: 'Montana', NE: 'Nebraska', NV: 'Nevada', NH: 'New Hampshire', NJ: 'New Jersey', NM: 'New Mexico', NY: 'New York', NC: 'North Carolina', ND: 'North Dakota', OH: 'Ohio', OK: 'Oklahoma', OR: 'Oregon', PA: 'Pennsylvania', RI: 'Rhode Island', SC: 'South Carolina', SD: 'South Dakota', TN: 'Tennessee', TX: 'Texas', UT: 'Utah', VT: 'Vermont', VA: 'Virginia', WA: 'Washington', WV: 'West Virginia', WI: 'Wisconsin', WY: 'Wyoming', PR: 'Puerto Rico'};
  public inprogress: boolean = false;
  paymentForm: FormGroup;
  firstName: FormControl;
  lastName: FormControl;
  country: FormControl;
  address1: FormControl;
  address2: FormControl;
  zip: FormControl;
  city: FormControl;
  state: FormControl;
  recaptcha: FormControl;
  public isCaptchaRequired: string = 'false';
  public isResourceLoaded: boolean = false;
  public reCaptchaSiteKey: string = '';

  ngOnInit(): void {
    this.productType = this.activatedRoute.snapshot.paramMap.get('productType');
    const externalProductList = ['geotax', 'sdm', 'mipro', 'geocoding', 'software-apis'];
    if(externalProductList.indexOf(this.productType) < 0){
      window.location.href = '/undefined';
    }
    switch (this.productType) {
      case 'geocoding':
        this.docTitle = 'MapMarker';
        break;
      case 'sdm':
        this.docTitle = 'Precisely Data Experience';
        break;
      case 'geotax':
        this.docTitle = 'GeoTAX';
        break;
      case 'mipro':
        this.docTitle = 'MapInfo Pro';
        break;
      default:
        this.docTitle = 'Precisely APIs';
    }
    this.titleService.setTitle(this.docTitle);
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(params.plan){
          this.resourceService.checkIfPlanIsValid(params.plan, this.productType).subscribe(
            success => {
              if(success.planValid === true){
                this.planType = params.plan;
                if(!this.userService.ifUserLoggedIn()){
                  window.location.href = '/upgrade/'+this.productType+'?plan='+this.planType;
                }
              }
              else{
                window.location.href = '/undefined';
              }
            }
          );
        }
        else{
          window.location.href = '/undefined';
        }
      }
    );
    this.resourceService.getresources().subscribe(
      success => {
        this.isResourceLoaded = true;
        this.isCaptchaRequired = success.isCaptchaRequired;
        this.reCaptchaSiteKey = success.reCaptchaSiteKey;
        this.resourceService.getRecurlyConfig(this.productType).subscribe(
          success =>{
            recurly.configure({
              publicKey: success.rPKey,
              required: [ 'cvv' ]
            });
            const elements = recurly.Elements();
            const cardElement = elements.CardElement({​
              inputType: 'mobileSelect',
              style: {​
                fontSize: '1em',
                padding: '10px 12px',
                height: '40px',
                placeholder: {​
                  color: 'gray !important',
                  fontWeight: 'bold',
                  content: {​
                    number: 'Card number',
                    cvv: 'CVV'
                  }​
                }​,
                invalid: {​
                  fontColor: 'red'
                }​
              }​
            }​);
            cardElement.attach('#recurly-elements');
            const compEle = this;
            document.querySelector('#recurly-form').addEventListener('submit', function(event){
              const form = this;
              event.preventDefault();​
              compEle.submitRecurlyForm(elements, form);
            });
            if(success.rd.length){
              this.paymentInfo = success.rd[0];
              this.hideRecurlyForm = true;
            }
            else{
              this.hideRecurlyForm = false;
            }
          }
        );
        this.createFormControls();
        this.createForm();
      }
    );
    this.userData = sessionStorage.getItem('userData');
    if(this.userData !== null){
      this.userData = JSON.parse(this.userData);
    }
    this.resourceService.getCountries().subscribe(
      success => {
        this.countryList = success;
        this.setCountryCodeStateList();
      }
    );
  }

  backClicked = function(){
    window.location.href = "/signin/" + this.productType;
  };
  createFormControls = function() {
    this.firstName = new FormControl('', [Validators.required, Validators.maxLength(50)]);
    this.lastName = new FormControl('', [Validators.required, Validators.maxLength(50)]);
    this.country = new FormControl('United States');
    this.address1 = new FormControl('', [Validators.required]);
    this.address2 = new FormControl('');
    this.zip = new FormControl('', [Validators.required]);
    this.city = new FormControl('', [Validators.required]);
    this.state = new FormControl('', [Validators.required]);
    if(this.isCaptchaRequired === 'true'){
      this.recaptcha = new FormControl('', [Validators.required]);
    }
  };
  createForm = function() {
    let formGropuObj = {
      firstName: this.firstName,
      lastName: this.lastName
    };
    if(this.isCaptchaRequired === 'true'){
      formGropuObj['recaptcha'] = this.recaptcha;
    }
    formGropuObj['country'] = this.country;
    formGropuObj['address1'] = this.address1;
    formGropuObj['address2'] = this.address2;
    formGropuObj['zip'] = this.zip;
    formGropuObj['city'] = this.city;
    formGropuObj['state'] = this.state;
    this.paymentForm = new FormGroup(formGropuObj);
  };
  setCountryCodeStateList = function(){
    for(let i=0; i<this.countryList.length; i++){
      if(this.countryList[i].name === this.country.value){
        this.countryCode = this.countryList[i].isoCode3;
        this.stateList = this.countryList[i].states;
        this.stateList.sort();
      }
    }
    this.state.setValue(this.stateList[0]);
    if(this.countryCode === 'USA'){
      this.showConfirmAddr = false;
      this.confirmAddrRadio = null;
      this.showRecurlySubmitBtn = false;
    }
    else{
      this.showConfirmAddr = false;
      this.confirmAddrRadio = null;
      this.showRecurlySubmitBtn = true;
    }
  };
  selectConfirmedAddress = function(){
    let address = this.verifiedAddrRes[this.confirmAddrRadio];
    this.address1.setValue(address.AddressLine1);
    this.address2.setValue(address.AddressLine2);
    this.city.setValue(address.City);
    this.state.setValue(this.usStateMap[address.StateProvince]);
    this.zip.setValue(address.PostalCode);
  };
  resetAddressFields = function(){
    if(this.countryCode == 'USA'){
      this.showConfirmAddr = false;
      this.confirmAddrRadio = null;
      this.showRecurlySubmitBtn = false;
    }
  };
  reviewPaymentDetails = function(){
    this.paymentForm.touched = true;
    this.firstName.touched = true;
    this.lastName.touched = true;
    this.address1.touched = true;
    this.address2.touched = true;
    this.zip.touched = true;
    this.city.touched = true;
    this.state.touched = true;
    if(this.isCaptchaRequired === 'true'){
      this.recaptcha.touched = true;
    }
    if(this.paymentForm.valid){
      let addrPayload = {
        'address1': this.address1.value,
        'address2': this.address2.value,
        'city': this.city.value,
        'country': 'US',
        'state': this.state.value,
        'postal_code': this.zip.value
      };
      this.userService.verifyAddress(addrPayload).subscribe(
        success => {
          if(success.Output.length > 0){
            if(success.Output.length == 1){
              let addConfidence = success.Output[0].Confidence;
              if(addConfidence < 80){
                this.paymentErrMsg = "We couldn't verify this address. Please double check that the address details are correct.";
                this.isPaymentErr = true;
                this.showRecurlySubmitBtn = false;
                this.verifiedAddrRes = null;
                this.showConfirmAddr = false;
                document.querySelector('#paymentNotification').scrollIntoView();
              }
              else{
                this.showRecurlySubmitBtn = true;
                this.verifiedAddrRes = success.Output;
                this.showConfirmAddr = true;
              }
            }
            else{
              this.showRecurlySubmitBtn = true;
              this.verifiedAddrRes = null;
              this.showConfirmAddr = true;
            }
          }
          else{
            this.paymentErrMsg = "We couldn't verify this address. Please double check that the address details are correct.";
            this.isPaymentErr = true;
            this.showRecurlySubmitBtn = false;
            this.verifiedAddrRes = success.Output;
            this.showConfirmAddr = false;
            document.querySelector('#paymentNotification').scrollIntoView();
          }
        }
      );
    }
  };
  submitRecurlyForm = function(elements, form){
    if(this.paymentForm.valid){
      if(this.confirmAddrRadio == null && this.countryCode == 'USA'){
        this.paymentErrMsg = 'Please select a corrected address.';
        this.isPaymentErr = true;
        return false;
      }
      this.paymentErrMsg = '';
      this.isPaymentErr = false;
      let fields = {​
        'first_name': 'First Name',
        'last_name': 'Last Name',
        'address1': 'Address',
        'address2': 'Address 2',
        'city': 'City',
        'state': 'State',
        'postal_code': 'Postal Code',
        'number': 'CC Number',
        'month': 'CC Month',
        'year': 'CC Year',
        'cvv': 'CVV'
      }​;
      const that = this;
      that.recurlyErrMsg = '';
      that.isRecurlyErr = false;
      recurly.token(elements, form, function(err, token){
        if(err){
          let failedMsgArr = [];
          if(err.name === 'api-error' || err.name === 'validation'){
            for (let i = 0; i < err.details.length; i++) {​
              failedMsgArr.push(`${​fields[err.details[i].field]}​ ${​err.details[i].messages[0]}​`);
            }​
          }​
          else {​
            failedMsgArr.push(err.message);
          }
          for (let i = 0; i < failedMsgArr.length; i++) {
            if(i !== 0){
              if(i === failedMsgArr.length -1){
                that.recurlyErrMsg += ' and ';
              }
              else{
                that.recurlyErrMsg += ', ';
              }
            }
            that.recurlyErrMsg += failedMsgArr[i];
          }​
          that.recurlyErrMsg += '.';
          that.isRecurlyErr = true;
        }
        else{
          let payload = {
            planName: that.planType,
            token: token.id
          };
          if(that.isCaptchaRequired === 'true'){
            payload['recaptcha'] = that.recaptcha.value;
          }
          that.changeSubscription(payload);
        }
      });
    }
  };
  processNextStep = function(){
    switch (this.selectPaymentOpt) {
      case 'existing':
        this.hideRecurlyForm = true;
        this.changeSubscription({planName: this.planType});
        break;
      case 'new':
        this.hideRecurlyForm = false;
        break;
    }
  };
  changeSubscription = function(payload){
    this.inprogress = true;
    this.notificationMsg = '';
    this.userService.modifySubscription(payload, this.productType).subscribe(
      success => {
        this.inprogress = false;
        this.paymentForm.reset();
        window.location.href = '/thanks/'+this.productType+'?resultTag=true&scenario=upgradeSuccess';
      },
      error => {
        this.inprogress = false;
        switch(error.status){
          case 400:
            this.notificationMsg = 'We’re having issue in processing your request. Please try again.';
            break;
          default:
            this.paymentForm.reset();
            window.location.href = '/thanks/'+this.productType+'?resultTag=false';
            break;
        }
      }
    );
  };
}
